import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108')
];

export const server_loads = [9,2,4];

export const dictionary = {
		"/admin": [63,[9],[10]],
		"/admin/(auth)/agency": [64,[9,11],[10]],
		"/admin/auth/login": [108,[9,12],[10]],
		"/admin/(auth)/config/billing-settings": [65,[9,11],[10]],
		"/admin/(auth)/domains": [66,[9,11],[10]],
		"/admin/(auth)/logs": [67,[9,11],[10]],
		"/admin/(auth)/operator/paymentsReport": [69,[9,11],[10]],
		"/admin/(auth)/operator/payment": [68,[9,11],[10]],
		"/admin/(auth)/operator/payoutDetails": [70,[9,11],[10]],
		"/admin/(auth)/operator/payoutManage": [71,[9,11],[10]],
		"/admin/(auth)/operator/rating-bonus": [72,[9,11],[10]],
		"/admin/(auth)/operator/refund": [73,[9,11],[10]],
		"/admin/(auth)/payments-groups": [75,[9,11],[10]],
		"/admin/(auth)/payments": [74,[9,11],[10]],
		"/admin/(auth)/permissions": [76,[9,11],[10]],
		"/admin/(auth)/permissions/settings": [78,[9,11],[10]],
		"/admin/(auth)/permissions/[id]": [77,[9,11],[10]],
		"/admin/(auth)/product/subscription": [79,[9,11],[10]],
		"/admin/(auth)/product/time-package": [80,[9,11],[10]],
		"/admin/(auth)/refunds": [81,[9,11],[10]],
		"/admin/(auth)/reports": [82,[9,11],[10]],
		"/admin/(auth)/rooms": [83,[9,11],[10]],
		"/admin/(auth)/rooms/alerts": [85,[9,11],[10]],
		"/admin/(auth)/rooms/alerts/settings": [86,[9,11],[10]],
		"/admin/(auth)/rooms/[id]": [84,[9,11],[10]],
		"/admin/(auth)/stats/ad": [87,[9,11],[10]],
		"/admin/(auth)/story": [88,[9,11],[10]],
		"/admin/(auth)/story/settings": [89,[9,11],[10]],
		"/admin/(auth)/terms": [90,[9,11],[10]],
		"/admin/(auth)/users": [91,[9,11],[10]],
		"/admin/(auth)/users/rating": [94,[9,11],[10]],
		"/admin/(auth)/users/referrals": [95,[9,11],[10]],
		"/admin/(auth)/users/referrals/details": [96,[9,11],[10]],
		"/admin/(auth)/users/subscriptions": [97,[9,11],[10]],
		"/admin/(auth)/users/time-charges": [98,[9,11],[10]],
		"/admin/(auth)/users/time-package": [99,[9,11],[10]],
		"/admin/(auth)/users/verifications": [100,[9,11],[10]],
		"/admin/(auth)/users/verifications/age": [101,[9,11],[10]],
		"/admin/(auth)/users/verifications/history": [102,[9,11],[10]],
		"/admin/(auth)/users/[id]": [92,[9,11],[10]],
		"/admin/(auth)/users/[id]/reports": [93,[9,11],[10]],
		"/admin/(auth)/utils/billing-payment-methods": [103,[9,11],[10]],
		"/admin/(auth)/utils/billing-report": [104,[9,11],[10]],
		"/admin/(auth)/utils/experiments": [105,[9,11],[10]],
		"/admin/(auth)/utils/feature-toggles": [106,[9,11],[10]],
		"/admin/(auth)/utils/time-packages-by-countries": [107,[9,11],[10]],
		"/(main)/[[lang=lang]]/(auth)/block": [16,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/chat-history": [17,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/favorites": [18,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/healthz": [~47,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/(info)/info/2257": [43,[2,8],[3]],
		"/(main)/[[lang=lang]]/(public)/login": [48,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/login/[oauthMethod]/failure": [49,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/login/[oauthMethod]/verify": [50,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/mail-subscription/unsubscribe": [51,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/(payment)/payment-failed": [~13,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/(payment)/payment-failure": [14,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/(payment)/payment-success": [15,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/(info)/privacy": [44,[2,8],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile": [19,[2,4,5],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile/change-password": [20,[2,4,5],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile/deactivate": [21,[2,4,5],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile/payment-details": [22,[2,4,5],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile/subscription": [23,[2,4,5],[3]],
		"/(main)/[[lang=lang]]/(public)/promo": [52,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/referral": [24,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/(info)/report-content": [~45,[2,8],[3]],
		"/(main)/[[lang=lang]]/(public)/reset-password": [~53,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/reset-password/expired": [54,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/reset-password/success": [55,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/call": [26,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/call/no-answer": [27,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/search": [28,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/start": [29,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/[id]": [25,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(public)/sentry-example": [56,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/session-exists": [30,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/signup": [57,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step1": [32,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step2": [33,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step3": [34,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step4": [35,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step5": [36,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step6": [37,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step7": [38,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(public)/signup/verify": [~59,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/signup/[oauthMethod]/failure": [58,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/[oauthMethod]/success": [31,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(public)/start": [60,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/story/edit": [39,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/story/upload": [40,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/subscription/buy": [41,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/(info)/terms": [46,[2,8],[3]],
		"/(main)/[[lang=lang]]/(public)/unsupported-device": [61,[2],[3]],
		"/(main)/[[lang=lang]]/(public)": [42,[2],[3]],
		"/(main)/[[lang=lang]]/[...path]": [62,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.svelte';